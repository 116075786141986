//distances/spacing
$form-padding: 16px;

// Roundings
$roundings-radius-large: 6px;
$roundings-radius-medium: 4px;
$roundings-radius-small: 2px;

// Brand colors:
$brand-color-black: #000;
$brand-color-polytech-grey: #54585a;
$brand-color-stone-grey: #86898a;
$brand-color-concrete-grey: #b8b9ba;
$brand-color-blade-grey: #ebebeb;
$brand-color-background-grey: #f7f7f7;
$brand-color-white: #fff;
$brand-color-zebra-stribe: #fafafa;

$brand-color-brown: #78190a;
$brand-color-red: #dc2d0a;
$brand-color-polytech-orange: #ff6400;
$brand-color-sun-orange: #ff910f;
$brand-color-yellow: #ffc823;
$brand-color-sand: #dcd2b9;
$brand-color-street-blue: #4682b4;
$brand-color-disabled-text: $brand-color-concrete-grey;
$brand-color-disabled-background: $brand-color-stone-grey;

// Accent colors:
$accent-color-blue-1: #0066cc;
$accent-color-blue-2: #00476c;
$accent-color-turquoise-1: #028767;
$accent-color-turquoise-2: #006c51;

// Usage:
$color-cta-highlight: $brand-color-polytech-orange;
$color-link: $accent-color-blue-1;
$color-success: $accent-color-turquoise-1;
$color-warning: $brand-color-yellow;
$color-error: $brand-color-red;
$color-accent: $accent-color-turquoise-2;

$form-field-background-color: $brand-color-blade-grey;

// Shadows
$shadow-card: 0px 10px 30px rgba(0, 0, 0, 0.1);
$shadow-sidebar: 2px 0px 8px rgba(0, 0, 0, 0.18);
$shadow-page-header: 0px 4px 8px rgba(0, 0, 0, 0.04);

//font sizes
$font-size-medium-small: 16px;

$font-size-xl: 36px;
// Font style
$font-weight-medium: 500;

// Colors
$brand-color-polytech-grey-opacity: rgba(134, 137, 138, 0.1);
$brand-color-polytech-blade-grey: #ebebeb;
