.search-field {
  .mat-form-field-outline {
    // border: 1px solid #ebebeb;
    border-radius: 5px;
    height: 40px;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $brand-color-background-grey;
  }
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $brand-color-background-grey;
  }
  .mat-select-value-text {
    color: $brand-color-black;
    font-weight: bold;
  }
  .mat-form-field-label {
    color: $brand-color-polytech-grey;
    font-weight: bold;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    display: none;
  }

  mat-form-field {
    width: 200px;
  }

  .mat-form-field.mat-focused mat-label {
    display: none;
  }

  .mat-form-field-outline {
    background-color: $brand-color-background-grey;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $brand-color-background-grey;
  }

  .mat-form-field-infix {
    border-top: 0.74375em solid transparent;
    border: unset;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    display: none !important;
  }
}

.search-field.inverted {
  .mat-form-field-outline {
    border: none;
  }

  div.mat-form-field-outline div {
    background-color: #ebebeb;
    border-top-color: #ffffff !important;
  }
}
