@import "./theme";
@import "./assets/styles/material-styles.scss";
@import "./assets/styles/variables";
@import "mapbox-gl/dist/mapbox-gl.css";

html {
  font-size: 14px;
}

body {
  margin: 0;
}
svg {
  vertical-align: sub;
  pointer-events: none;
}
// google map styling:
// these styles doesnt work if put in map-view.component.scss probably bc angular changes the actual classnames in the view/css to encapsulate component styling
$cluster-radius: $roundings-radius-large;
$section-height: 28px;

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: url("assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url("assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: url("assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: url("assets/fonts/Roboto-Black.ttf") format("truetype");
}

.custom-clustericon {
  :focus {
    outline: none;
  }
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: $section-height;
  letter-spacing: 0.1px;

  .status-icon {
    width: 16px;
    height: 16px;
    svg path {
      fill: #fff;
    }
  }
  .content {
    // padding: 3px;
    vertical-align: center;
    // height: 100%;

    // text-align: left;
    // margin: 0 auto;
    // height: $section-height;

    img {
      display: inline-block;
      vertical-align: middle;
      margin-left: 4px;
    }
    .number {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      margin-left: 4px;
    }
  }
  .upper {
    background-color: $accent-color-turquoise-2;
    color: $brand-color-white;

    // border-radius: $cluster-radius $cluster-radius 0 0;
  }
  .lower {
    background-color: $brand-color-polytech-grey;
    color: $brand-color-white;
    // border-radius: 0 0 $cluster-radius $cluster-radius;
  }
  .inService {
    background-color: $brand-color-street-blue;
    color: $brand-color-white;
  }
  .inReturn {
    background-color: $brand-color-polytech-orange;
    color: $brand-color-white;
  }
  .grid-quatro {
    display: grid;
    grid-template-columns: 80px 80px;
    border-radius: $cluster-radius $cluster-radius $cluster-radius $cluster-radius;
    overflow: hidden;
    text-align: left;
  }
  .grid-double {
    display: grid;
    // grid-template-rows: 32px 32px 32px 32px;
    border-radius: $cluster-radius $cluster-radius $cluster-radius $cluster-radius;
    overflow: hidden;
    width: 74px;
    text-align: left;
  }
  .grid-three {
    display: grid;
    // grid-template-rows: 32px 32px 32px 32px;
    border-radius: $cluster-radius $cluster-radius $cluster-radius $cluster-radius;
    overflow: hidden;
    width: 74px;
    text-align: left;
  }
  .single div {
    border-radius: $cluster-radius $cluster-radius $cluster-radius $cluster-radius;
    overflow: hidden;
    width: 74px;
  }
  /* .utilization .upper {
    background-color: $accent-color-turquoise-2;
  } */
  .humidity .upper {
    background-color: $brand-color-yellow;
    color: $brand-color-black;
  }
  .humidity .lower {
    background-color: $brand-color-blade-grey;
    color: $brand-color-polytech-grey;
  }
}
.numeric-field {
  width: 100px;
  height: 40px;
  margin-right: 8px;
}
.pb-l {
  padding-bottom: 16px;
}

//@import "./assets/styles/icons.scss";

// general anchor style
a:link {
  text-decoration: none;
  color: $brand-color-black;
}

a:hover {
  text-decoration: underline;
}
a:visited {
  color: $brand-color-black;
}
// placeholder styling
::placeholder {
  font-style: italic;
}
// spinners on buttons:
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: $brand-color-polytech-orange;
  animation: spinner 0.8s linear infinite;
}

app-zx-project-details {
  .map-container {
    border-radius: $roundings-radius-large;
  }
}

.mat-card {
  padding: 24px;
  border-radius: 12px !important;
  box-sizing: border-box;
  // border: unset !important;
  .count {
    margin-left: 4px;
    margin-top: 6px;
    .count-label {
      //font-style: italic;
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 600;
      color: $brand-color-stone-grey;
      margin-top: 4px;
    }

    .count-number {
      font-weight: 700;
      color: $brand-color-black;
      font-size: 26px;
      letter-spacing: 0ch;
    }
  }
}

.mat-flat-button.mat-primary.mat-button-disabled {
  background-color: $brand-color-disabled-background;
  color: $brand-color-disabled-text;
}
.mat-flat-button.btn-grey {
  background-color: $brand-color-blade-grey;
  color: $brand-color-black;
}

.mat-flat-button.btn-red {
  background-color: $brand-color-red;
  color: $brand-color-white;
}

.gm-ui-hover-effect img {
  content: url("/assets/icons/cancel.svg");
  margin-top: 16px !important;
  margin-left: 0px !important;
}
.spinner-wrapper {
  height: 100vh;
  position: relative;
}

mat-spinner {
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filtercard {
  background-color: $brand-color-background-grey;
  margin: 20px;
  padding: 12px !important;
  box-shadow: none !important;
  &.devices {
    margin-left: 0px;
  }
  p.form-field-header {
    margin: 0;
  }
}
.shadow-card {
  box-shadow: 0 10px 30px #0000001a !important;
}

/* these are to adjust the height of the angular-material controls */
.mat-form-field-wrapper {
  padding-bottom: 0px;
}

.mat-form-field-infix {
  padding: 0.5em 0 0.7em 0;
}

.mat-form-field-type-mat-input .mat-form-field-infix,
.mat-form-field-type-mat-select .mat-form-field-infix {
  padding: 0.5em 0 0.5em 0 !important;
  // line-height: 12px !important;
}

.mat-form-field-label-wrapper {
  top: -1.12em !important;
  padding-top: 1.12em !important;
}

//.mat-form-field-wrapper {
//  margin: 0 !important;
//}

.solution-tabs {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: black;
  border-bottom: 1px solid #ebebeb !important;
  background-color: $brand-color-background-grey;
}
.forms-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form-field-container:not(:last-child) {
    margin-right: 16px;
  }

  .form-field-container {
    display: flex;
    flex-direction: column;
    // mat-form-field {
    //   width: 300px;
    // }
    .form-field-header {
      font-size: 13px;
      color: $brand-color-polytech-grey;
      font-weight: $font-weight-medium;
    }
  }
}

.apply-button {
  margin: auto;
  color: white;
  background-color: $brand-color-polytech-orange;
  width: 70px;
  margin-left: 16px;
  border: unset;
  cursor: pointer;
}
