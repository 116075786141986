@import "../variables";

.form-container {
  margin-top: 20px;
  .form-field-container {
    display: flex;
    flex-direction: column;
    .mat-form-field-appearance-outline .mat-form-field-outline {
      height: 39px;
    }
    .mat-select-arrow {
      margin: 0 4px 3px 4px;
    }
    .mat-form-field-outline {
      background-color: $brand-color-blade-grey;
      border-radius: 5px;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: $brand-color-white;
    }
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      color: $brand-color-white;
    }
    .mat-select-value-text {
      margin: 0 4px 22px 4px;
    }
    .form-field-header {
      color: $brand-color-polytech-grey;
      font-weight: 500;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      height: 39px;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 3px 0;
    }
    .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
      padding: 2px 0px 0px 0px;
    }
    .mat-select-value-text {
      font-weight: bold;
    }
    .mat-datepicker-toggle {
      .mat-icon-button {
        max-width: 20px;
        margin-top: -11px;
      }
      .mat-datepicker-toggle-default-icon {
        max-width: 20px;
      }
      .mat-button-ripple-round,
      .mat-button-focus-overlay {
        display: none;
      }
    }
    .date-container,
    .time-container {
      display: flex;
      flex-direction: row;
      .mat-select-value-text {
        color: $brand-color-stone-grey;
      }
      svg {
        margin-top: -5px;
      }
    }
    &.main-page {
      /*  .mat-option-text {
        font-size: 18px !important;
        font-weight: 800 !important;
      } */
      mat-form-field {
        width: 200px;
      }
      /*  .mat-select-value-text {
        color: $brand-color-stone-grey;
        font-size: 18px;
      } */
      .mat-form-field-appearance-outline .mat-form-field-outline {
        height: 40px;
      }
    }
  }
}
