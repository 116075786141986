app-input-field {
  .mat-form-field-outline {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    height: 38px;
  }
  /*
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $brand-color-white;
  }
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $brand-color-white;
  }
  */
  .mat-select-value-text {
    // color: $brand-color-black;
    font-weight: bold;
  }
  .mat-form-field-label {
    // color: $brand-color-polytech-grey;
    font-weight: bold;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    display: none;
  }

  mat-form-field {
    width: 200px;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.9em 0 1.1em 0;
    border-top-width: 10px;
  }
  /*
  .mat-form-field-outline {
    background-color: $brand-color-background-grey;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $brand-color-background-grey;
  }
  */
}

.long-input .mat-form-field-appearance-fill .mat-form-field-flex {
  width: 568px;
}

app-input-field.inverted {
  .mat-form-field-outline {
    border: none;
  }

  div.mat-form-field-outline div {
    background-color: #ebebeb;
  }
}
