.select-field {
  .mat-form-field-outline {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    height: 38px;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $brand-color-white;
  }
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $brand-color-white;
  }
  .mat-select-value-text {
    color: $brand-color-black;
    font-weight: bold;
  }
  .mat-form-field-label {
    color: $brand-color-polytech-grey;
    font-weight: bold;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    display: none;
  }
  
  mat-form-field {
    width: 200px;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.9em 0 1.1em 0;
  }

  .mat-form-field-outline {
    background-color: $brand-color-white;
  }
  
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $brand-color-white;
  }
}

.select-field.inverted {
  .mat-form-field-outline {
    border: none;
  }

  div.mat-form-field-outline div {
    background-color: #ebebeb;
  }

  .mat-form-field-disabled {
    .mat-select-value-text {
      color: #b6b7b8;
    }
    .mat-select-arrow {
      color: #878a8b;
    }
    div.mat-form-field-outline div {
      background-color: #878a8b;
    }
  }
}



