/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
@import "/src/assets/styles/variables";

.snackbar-background {
  background-color: white;
  color: black;
}
.toast-warning {
  background-color: $brand-color-yellow;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
.snackbar-size {
  width: auto;
  height: 108px;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  .toast-bottom-left {
    bottom: 12px;
    left: 12px;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  .toast-bottom-left {
    bottom: 12px;
    left: 12px;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
// TODO delete the rest of the css classes onece we get rid of the toastr from ngx
.error-snackBar {
  background-color: red;
  color: white;
}
.info-snackBar {
  background-color: #ebebeb;
  color: black;
}
.success-snackBar {
  background-color: #028767;
  color: white;
}
.warning-snackBar {
  background-color: #ffc823;
  color: black;
}
