.create-user-table {
  table-layout: fixed;
  border-color: $brand-color-blade-grey;
  margin-bottom: 30px;
  margin-top: 10px;
  table {
    width: 100%;
  }
  .mat-row:nth-child(odd) {
    background-color: $brand-color-zebra-stribe;
  }
  th.mat-header-cell {
    border-bottom-color: $brand-color-blade-grey;
  }
  td.mat-cell {
    border-bottom: none;          
  }
  th {
    font-size: 16px;
    font-weight: 500;
    color: $brand-color-polytech-grey;
  }
  td {
    font-size: 16px;
    font-weight: 500;
    .cell-container {
      display: flex;
      height: 52px;
      align-items: center;
      justify-content: space-between;
      .user-action {
        display: flex;
        align-items: center;
      }
      .chevron {
        cursor: pointer;
      }
    }        
  }
}