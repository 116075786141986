.menu-wrapper {
  width: 260px;
  border-radius: 8px;
  &.short {
    width: 100px;
  }
  &.side-menu {
    width: 200px;
  }
  .mat-menu-item {
    font-size: 16px;
    font-family: 'Roboto';
    color: $brand-color-polytech-grey;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg-icon {
      margin-right: 10px;
    }
    p {
      margin: 0;
    }
  }
}

.mat-menu-panel {
  border-radius: 8px;
}