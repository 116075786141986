// filter container css
@import "/src/assets/styles/_variables.scss";
@import "/src/assets/styles/_variables.scss";
.filter-container {
  display: flex;
  align-items: center;
}

.border-bottom-1 {
  border-bottom: 1px solid #ebebeb;
}
.border-top-1 {
  border-top: 1px solid #ebebeb;
}

button.mat-menu-item {
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24;
  font-weight: 500;
}

.button-size {
  width: 250px !important;
  height: 40px !important;
  font-weight: bold;
}

.space-between {
  justify-content: space-between;
}
.filter-button {
  background-color: rgba(70, 130, 180, 0.2); //rbga(70, 130, 180, 0.2);
  border: solid 1px #4682b4 !important;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 14px;
  color: black;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-right: 8px !important;
}
.filter-datalog {
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
}
.black-icon {
  svg {
    g {
      fill: black;
    }
  }
}
.grey-icon {
  svg {
    g {
      fill: rgba(0, 0, 0, 0.38);
    }
  }
}
