@import "../variables";

button {
  padding: 0px;
  border-radius: 4px;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  text-align: center;
  padding: 0 12px;
  line-height: 20px !important;
  div {
    display: flex;
    height: 17px;
    &.single_column {
      padding: 0;
      display: unset;
    }
    .noIcon {
      margin-left: 5px;
    }
  }
}

.mat-flat-button {
  line-height: 17px;
  min-width: 40px;
}

.mat-button-wrapper {
  width: 100px;
}

.bottom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  .cancel button {
    background-color: $brand-color-white;
  }
}

.submit-container {
  align-self: flex-end;
  margin-top: 70px;
  .disabled button {
    background-color: $brand-color-stone-grey;
    color: $brand-color-concrete-grey;
    pointer-events: none;
  }
  .cancel button {
    background-color: $brand-color-white;
  }
}

table {
  .add-btn {
    &.added button {
      background-color: $accent-color-turquoise-1;
      color: $brand-color-white;
    }
    button {
      height: auto;
      padding: 5px 10px;
      font-size: 13px;
      font-weight: 700;
    }
  }
  .add-btn.added button {
    background-color: $accent-color-turquoise-1;
    color: $brand-color-white;
  }
}